import Head from 'next/head'
import Image from 'next/image'
import Link from 'next/link'
import { TeamGamesMetaDescription, TeamGamesOGImagePath } from 'utils/constants'

import AppHeader from '../components/app_header'
import ModalJoinOuting from '../components/modals/modal_join_outing'

import styles from '../styles/404.module.scss'

export const Custom404 = () => {
  return (
    <div className={styles.page_container} id="content_not_found_page">
      <Head>
        <title>Content Not Found | Team Games</title>
        <link rel="icon" href="/images/modal_logo.png" />
        <meta name="description" content={TeamGamesMetaDescription} />
        <meta name="og:image" content={TeamGamesOGImagePath} />
      </Head>
      <AppHeader transparent />
      <div className={styles.page_body}>
        <div className={styles.page_title + ' hero'}>404</div>
        <div className={styles.page_subtitle + ' heading_1'}>Content Not Found</div>
        <div className={styles.page_description + ' description_large'}>
          Something went wrong, and we could not find the page you were looking for. Check out the following pages
          instead:
        </div>
        <div className={styles.page_btns}>
          <Link href="/" passHref>
            <div className={styles.page_btn}>
              <a>Home Page</a>
            </div>
          </Link>
          <Link href="/help-details" passHref>
            <div className={styles.page_btn}>
              <a>Help & Details</a>
            </div>
          </Link>
          <Link href="/contact-us" passHref>
            <div className={styles.page_btn}>
              <a>Contact</a>
            </div>
          </Link>
        </div>
        <div className={styles.page_img}>
          <Image src="/images/404.png" alt="404 Content Not Found" layout="fill" />
        </div>
      </div>
      <ModalJoinOuting />
    </div>
  )
}

export default Custom404
